<template>
  <div
    class="init pb-4"
  >
    <img
      class="logo mt-2 mb-3"
      src="/logo.svg"
      :alt="$config.brand.name"
    >
    <h2 class="text-primary">
      {{ $t('pages.auth.init.title') }}
    </h2>
    <p class="text-lined pr-1">
      {{ $t('pages.auth.init.description') }}
    </p>

    <touch-button
      variant="primary"
      class="mt-2 font-weight-normal btn-rounded"
      size="lg"
      @action="next"
    >{{ $t('pages.auth.init.start-button') }}
    </touch-button>
  </div>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
// eslint-disable-next-line import/no-cycle
import { NAME_LOGIN, NAME_SERVER } from '@/router/routes/auth'

export default {
  components: {
    TouchButton,
  },
  data() {
    return {}
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    next() {
      if (this.$store.getters['server/isDefine']) this.$router.push({ name: NAME_LOGIN })
      else this.$router.push({ name: NAME_SERVER })
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 106px;
}
</style>
